<template>
  <div>
    <div class="mb-4 text-right">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="#F87368" v-bind="attrs" v-on="on">
            More Actions
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="toggleFilterMenu">
            <v-list-item-title>
              <v-btn outlined color="#F87368" class="mr-2">
                <v-icon color="f87368">mdi-view-column-outline</v-icon>Add
                columns
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="openModal">
            <v-list-item-title>
              <v-btn>
                <v-icon class="mr-2">mdi-download</v-icon>Download
              </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="createNewdialog = true">
            <v-list-item-title>
              <v-btn color="primary"> <v-icon>mdi-plus</v-icon>Create </v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="userBulkdialog = true">
            <v-list-item-title>
              <v-btn color="secondary"> <v-icon>mdi-plus</v-icon>Upload </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-dialog v-model="userBulkdialog" width="400px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h1>Bulk Upload Clients</h1>
        </v-card-title>

        <v-card-text>
          <product-bulk-create />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="userBulkdialog = false"
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createNewdialog" width="auto">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-dialog v-model="dialog3" width="800">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Create New Client
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <h1>Create New Client</h1>
                  </v-card-title>

                  <v-card-text>
                    <user-create />
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog3 = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> </v-col
            ><v-col>
              <v-btn
                dark
                color="secondary"
                @click="$router.push('/admin/company/0/edit')"
                >Create new Company</v-btn
              >
            </v-col></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="createNewdialog = false"
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-menu v-model="showMenu" :position-x="menuX" :position-y="menuY">
      <v-list>
        <v-list-item-group>
          <v-list-item v-for="column in tableHeaders" :key="column.text">
            <v-checkbox v-model="column.show" :label="column.text"></v-checkbox>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-data-table
      :expanded.sync="expanded"
      v-model="selectedItems"
      :headers="filteredHeaders"
      :search="search"
      :items="archivedUsersData"
      :item-value="itemKey"
      :hide-default-footer="true"
      :loading="loading"
      show-select
    >
      <template v-slot:top>
        <v-row class="mx-2">
          <v-col cols="8"
            ><v-text-field
              outlined
              placeholder="Search Firstname,Surname Email or Tel No."
              v-model="searchText"
              @keydown.enter="performSearch"
              ><template #append
                ><v-btn
                  color="primary"
                  x-small
                  class="my-1"
                  @click="performSearch"
                  >Search</v-btn
                >
                <v-btn
                  color="secondary"
                  class="my-1"
                  x-small
                  @click="clearSearch"
                >
                  clear
                </v-btn>
              </template></v-text-field
            ></v-col
          >
          <v-col cols="2">
            <referral-picker
              :label="'Source'"
              :initial="sourceSelection"
              :chosen-referral.sync="sourceSelection"
              outlined
            />
          </v-col>
          <v-col cols="2">
            <assign-to-consultant
              :userIds="selectedItemIds"
              @consultant-assigned="fetchUsers"
            />
          </v-col>
        </v-row>
        <v-dialog v-model="isModalOpen" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Select Date Range</span>
            </v-card-title>
            <v-card-text>
              <v-date-picker v-model="selectedDates" range></v-date-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="dismissModal">Cancel</v-btn>
              <v-btn @click="applyFilter">Apply Filter</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.firstName="{ item }">
        <p color="primary" @contextmenu.prevent="openInNewTab(item.id)">
          <router-link :to="'/admin/user/' + item.id + '/edit'"
            >{{ item.firstName }}
          </router-link>
        </p>
      </template>
      <template v-slot:item.lastName="{ item }">
        <p color="primary" @contextmenu.prevent="openInNewTab(item.id)">
          <router-link :to="'/admin/user/' + item.id + '/edit'"
            >{{ item.lastName }}
          </router-link>
        </p>
      </template>
      <template v-slot:item.progress="{ item }">
        <v-select
          v-model="item.progress"
          class="mt-3"
          item-value="value"
          item-text="text"
          solo
          :items="progressTypes"
          @change="updateUser(item)"
        ></v-select>
      </template>
      <template v-slot:expanded-item="{ item }">
        <div class="ml-16">
          <p>test</p>
        </div>
      </template>

      <template v-slot:item.email="{ item }">
        <a color="primary" :href="`mailto:${item.email}`">
          {{ item.email }}
        </a>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <p>
          <span color="primary">{{ item.createdAt | getFormattedDate }}</span>
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon color="primary" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon color="error" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-pagination
      v-if="pagination.pages"
      v-model="pagination.page"
      :length="pagination.pages"
      :total-visible="15"
      @input="fetchUsers"
    ></v-pagination>
  </div>
</template>
<script>
import { gql } from "apollo-boost";
import { GET_USERS } from "../../../../queries";
import progressTypes from "../../../items/progressTyes.json";
import TableHeaders from "../../../items/UserTableHeaders.json";
import {
  selectItem,
  emailSelected,
  formatDate,
  generateMailtoLink,
  formatDateWithTime,
} from "../../../methods/user";
import ReferralPicker from "../../../components/Base/Pickers/ReferralPicker.vue";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import Moment from "moment";
import Swal from "sweetalert2";
import UserCreate from "../../../components/Base/User/UserCreate.vue";
import ProductBulkCreate from "../../../components/Base/ProductBulkCreate.vue";
import AssignToConsultant from "../Users/AssignToConsultant.vue";

export default {
  components: {
    ReferralPicker,
    UserCreate,
    ProductBulkCreate,
    AssignToConsultant,
  },
  name: "Archived",

  data() {
    return {
      archivedUsersData: [],
      searchText: "",
      dialog3: false,
      sourceSelection: "Medshield",
      isModalOpen: false,
      selectedDateRange: null,
      userBulkdialog: false,
      selectedDates: null,
      search: "",
      selectedItems: [],
      expanded: [],
      loading: false,
      progressTypes: progressTypes,
      tableHeaders: TableHeaders,
      itemKey: "id",
      showMenu: false,
      menuX: 0,
      menuY: 0,
      createNewdialog: false,
      selectedColumns: [],
      pagination: {
        page: 1,
        pages: 0,
      },
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    async updateUser(item) {
      // console.log(item);
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: item.id,
              progress: item.progress,
              consultant: item.consultant,
            },
          },
        });

        if (!response) {
          throw new Error("Something went wrong when updating the user!");
        }

        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message);
        }
        if (
          response.data &&
          response.data.updateUser &&
          response.data.updateUser.user
        ) {
          const updatedUser = response.data.updatedUser.user;
          item.progress = updatedUser.progress;
        }

        this.$swal("Success", "Data has been updated", "success");
        await this.fetchUsers();
      } catch (error) {
        console.error(error);
      }
    },
    handleRowClick(item) {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    dismissModal() {
      this.isModalOpen = false;
      this.selectedDates = null;
    },
    handleConsultantAssigned() {
      console.log("Consultant assigned event emitted");
      this.refreshUsers();
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatDateWithTime(date) {
      return formatDateWithTime(date);
    },
    async fetchAllUsers() {
      const allUsers = [];
      let page = 1;
      let hasNextPage = true;
      let limit = 0;

      while (hasNextPage) {
        const [start, end] = this.selectedDates;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const reasonableLimit = Math.max(this.archivedUsersData.length, 1000);

        const response = await this.$apollo.query({
          query: GET_USERS,
          variables: {
            query: {
              role: "lead",

              createdAt: {
                $gte: startDate.getTime(),
                $lte: endDate.getTime(),
              },
            },
            page,
            limit: reasonableLimit,
          },
        });

        const { records, paginator } = response.data.users;
        allUsers.push(...records);

        hasNextPage = paginator.hasNextPage;
        page++;

        if (!limit) {
          limit = paginator.totalCount;
        }
      }

      return allUsers;
    },

    async applyFilter() {
      if (!this.selectedDates) {
        // Show an error message or handle the case when no date range is selected
        return;
      }

      try {
        const loadingAlert = await Swal.fire({
          title: "Your download is in progress",
          html: "Please wait...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const allUsers = await this.fetchAllUsers();

        const dataToDownload = allUsers.map((user) => ({
          companyName: user.companyName || "No company assigned",
          firstName: user.firstName,
          lastName: user.lastName,
          referral: user.referral,
          referralNumber: user.referralNumber,
          leadDescription: user.leadDescription || "No description defined yet",
          source: user.source || "No source defined yet",
          email: user.email,
          telNumber: user.telNumber,
          role: user.role,
          progress: user.progress || "No status yet",
          consultantName: user.consultantName || "No consultant assigned",
          bio: user.bio || "No  notes",
          createdAt: this.formatDateWithTime(user.createdAt),
        }));

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Clients");

        const headerRow = sheet.addRow([
          "Company",
          "First Name",
          "Surname",
          "Referral",
          "Referral Code",
          "Lead Description",
          "Lead Source",
          "Email",
          "TelNumber",
          "Role",
          "Progress",
          "Consultant",
          "Notes",
          "Date",
        ]);
        headerRow.font = { bold: true };

        dataToDownload.forEach((user) => {
          sheet.addRow([
            user.companyName,
            user.firstName,
            user.lastName,
            user.referral,
            user.referralNumber,
            user.leadDescription,
            user.source,
            user.email,
            user.telNumber,
            user.role,
            user.progress,
            user.consultantName,
            user.bio,
            user.createdAt,
          ]);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `CRM-clients-${
          new Date().toISOString().split("T")[0]
        }.xlsx`;
        link.click();

        URL.revokeObjectURL(url);

        Swal.close();
        this.dismissModal(); // Dismiss the modal after successful download
      } catch (error) {
        console.error("Error downloading data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while downloading data. Please try again.",
        });
      }
    },
    dialogState(state) {
      this.addContactDialog = state;
    },
    toggleFilterMenu(event) {
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.showMenu = !this.showMenu;
    },

    openInNewTab(item) {
      const url = `/admin/user/${item.id}`;
      window.open(url, "_blank");
    },
    performSearch() {
      this.search = this.searchText;
      if (this.search.trim() !== "") {
        // this.$apollo.queries.users.refetch();
        this.fetchUsers();
      }
    },

    // refreshUsersQuery() {
    //   this.addContactDialog = false;
    //   this.$apollo.queries.users.refetch();
    // },
    async fetchUsers() {
      this.loading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query archivedUsers($limit: Int, $page: Int, $query: JSON!) {
              archivedUsers(limit: $limit, page: $page, query: $query) {
                records {
                  id
                  firstName
                  lastName
                  email
                  fullAddress
                  telNumber
                  altNumber
                  branch
                  referral
                  referralNumber
                  title
                  consultant
                  company
                  companyName
                  dob
                  consultantName
                  bio
                  leadDescription
                  source
                  progress
                  branchName
                  role
                  createdAt
                  updatedAt
                }
                paginator {
                  userCount
                  perPage
                  pageCount
                  currentPage
                  slNo
                  hasNextPage
                  hasPrevPage
                  next
                  prev
                }
              }
            }
          `,
          variables: {
            limit: 10,
            query: {
              role: "lead",
              source: this.sourceSelection,
              $or: [
                { firstName: { $regex: this.search, $options: "i" } },
                { lastName: { $regex: this.search, $options: "i" } },
                { email: { $regex: this.search, $options: "i" } },
                { telNumber: { $regex: this.search } },
              ],
            },
            page: this.pagination.page,
          },
          fetchPolicy: "network-only",
        });

        this.archivedUsersData = response.data.archivedUsers.records;
        this.pagination.pages = response.data.archivedUsers.paginator.pageCount;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching users:", error);
        this.loading = false; // Ensure loading is set to false in case of error
      }
    },

    // refreshUsers() {
    //   this.$apollo.queries.users.refetch();
    // },

    editItem(item) {
      this.$router.push(`/admin/user/${item.id}`);
    },

    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation ($id: ID!) {
                  deleteUser(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.fetchUsers();
              this.$swal(
                "Success!",
                "User was successfully deleted",
                "success"
              );
            }
          } catch (e) {
            console.log("error deleting", e.message);
          }
        }
      });
    },
    clearSearch() {
      this.searchText = "";
      this.search = "";
      this.fetchUsers();
    },
  },
  watch: {
    search(newValue, oldValue) {
      this.search = newValue;
      this.fetchUsers();
    },
    sourceSelection(newValue, oldValue) {
      this.sourceSelection = newValue;
      this.fetchUsers();
    },
    pagination: {
      handler(newValue, oldValue) {
        if (newValue.page !== oldValue.page) {
          this.fetchUsers();
        }
      },
      deep: true,
    },
  },

  computed: {
    filteredHeaders() {
      return this.tableHeaders.filter((column) => column.show);
    },
    selectedItemIds() {
      return this.selectedItems.map((item) => item.id);
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
.v-data-table__expand-icon.mdi-chevron-down {
  display: none !important;
}
</style>
